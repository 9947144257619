import React from 'react'
import CareNavigatorImage from '../../assets/images/care-navigator.png'
import DedicatedHealthCoachesImage from '../../assets/images/dedicated-coaches.png'

const OurProgram = ({ isBylypsa }) => {
  return (
    <div className="mt-[40px] px-[40px] pb-[24px]">
      {isBylypsa && (
        <>
          <div className="text-[32px] font-[700]  text-[#213053] leading-[40px] w-[80%]">
            Get our Care
          </div>
          <div className="text-[32px] font-[700]  text-[#213053] leading-[40px] w-[90%]">
            Program for <span className="text-[#4CAF50]">free</span> with
          </div>
          <div className="text-[32px] font-[700]  text-[#213053] leading-[40px] w-[80%] pb-[40px]">
            Bilypsa Purchase
          </div>
        </>
      )}
      {!isBylypsa && (
        <div className="text-[32px] font-[600]  text-[#213053] leading-[40px] w-[70%]">
          Our program offers you
        </div>
      )}
      {isBylypsa && (
        <div className="text-[24px] font-[600]  text-[#213053] leading-[40px] w-[70%]">
          Program Offerings
        </div>
      )}
      <div className="mt-[32px] relative right-5">
        <img
          src={isBylypsa ? DedicatedHealthCoachesImage : CareNavigatorImage}
          className="w-358"
        />
      </div>
      <div className="pl-[1.5px] mt-[12px]">
        <div className="text-[20px] text-[#1F2933] font-[600] leading-[26px]">
          {`${isBylypsa ? 'Dedicated Coaches' : 'Dedicated Care Specialist'}`}
        </div>
        <p className="text-[16px]  text-[#5A6774] mt-3 font-[300] leading-[24px]">
          {`${isBylypsa ? 'Get dedicated support from nutritionists, health coaches and psychologists to improve your liver condition' : 'Stay connected with your dedicated guide for constant care and support throughout your treatment journey'}`}
        </p>
      </div>
    </div>
  )
}

export default OurProgram
